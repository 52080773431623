/* eslint-disable import/order */
/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter } from 'react-router-dom';
import Routers from './routers';
import './App.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';
import './init-fcm';
import { useEffect } from 'react';
import { getToken } from 'firebase/messaging';

function App() {
  return (
    <BrowserRouter>
      <ReduxToastr />
      <Routers />
    </BrowserRouter>
  );
}

export default App;
