const defaultState = {
  isLoaded: false,
  country: null,
  list: [],
  showModal: false,
  item: {},
  students: [],
};

const teacher_room = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_TEACHER_ROOMS':
      return {
        ...state,
        list: action.data,
        isLoaded: true,
      };
    case 'SET_TEACHER_ROOM_STUDENTS':
      return {
        ...state,
        item: action.data,
        students: action.students,
        isLoaded: true,
      };
    case 'ADD_TEACHER_ROOM':
      return {
        ...state,
        list: [...state.list, action.data],
        isLoaded: true,
      };
    case 'UPDATE_TEACHER_ROOM':
      return {
        ...state,
        list: state.list.map((_i) =>
          _i._id == action.data._id ? { ..._i, ...action.data } : { ..._i }
        ),
        item:
          state.item && state.item._id == action.data._id
            ? { ...state.item, ...action.data }
            : state.item,
        isLoaded: true,
      };
    case 'DELETE_TEACHER_ROOM':
      return {
        ...state,
        list: state.list.filter((_i) => _i._id != action.data),
        isLoaded: true,
      };
    case 'SET_KEY_TEACHER_ROOM':
      return {
        ...state,
        [action.key]: action.data,
      };
    default:
      return state;
  }
};

export default teacher_room;
