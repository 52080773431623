import React from 'react';
import SpinnerCustom from '../SpinnerCustom/SpinnerCustom';
import s from './style.module.scss';

function SpinnerPage() {
  return (
    <div className={s.SpinnerPage}>
      <SpinnerCustom />
    </div>
  );
}

export default SpinnerPage;
