/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getToken, getMessaging } from 'firebase/messaging';
import SpinnerPage from './components/SpinnerPage/SpinnerPage';
import { sendWebToken } from './actions';

const Login = React.lazy(() => import('./pages/Login/Login'));
const Layout = React.lazy(() => import('./pages/Layout/Layout'));
const Admins = React.lazy(() => import('./pages/Admins/Admins'));
const Country = React.lazy(() => import('./pages/Country/Country'));
const Language = React.lazy(() => import('./pages/Language/Language'));
const Room = React.lazy(() => import('./pages/Room/Room'));
const Plan = React.lazy(() => import('./pages/Plan/Plan'));
const Subject = React.lazy(() => import('./pages/Subject/Subject'));
const Users = React.lazy(() => import('./pages/Users/Users'));
const Question = React.lazy(() => import('./pages/Question/Question'));
const Steps = React.lazy(() => import('./pages/Steps/Steps'));
const RegisterRole = React.lazy(() => import('./pages/RegisterRole/RegisterRole'));
const Subjects = React.lazy(() => import('./pages/Subjects/Subjects'));
const SubjectsUser = React.lazy(() => import('./pages/SubjectsUser/Subjects'));
const PersonalAchievements = React.lazy(() => import('./pages/PersonalAchievements/PersonalAchievements'));
const SubjectsItem = React.lazy(() => import('./pages/SubjectsItem/SubjectsItem'));
const Forgot = React.lazy(() => import('./pages/Forgot/Forgot'));
const Reset = React.lazy(() => import('./pages/Reset/Reset'));
const Account = React.lazy(() => import('./pages/Account/Account'));
const Password = React.lazy(() => import('./pages/Password/Password'));
const Questions = React.lazy(() => import('./pages/Questions/Questions'));
const Badgets = React.lazy(() => import('./pages/Badgets/Badgets'));
const Register = React.lazy(() => import('./pages/Register/Register'));
const Notifications = React.lazy(() => import('./pages/Notifications/Notifications'));
const Childrens = React.lazy(() => import('./pages/Childrens/Childrens'));
const Subscription = React.lazy(() => import('./pages/Subscription/Subscription'));
const TeacherRooms = React.lazy(() => import('./pages/TeacherRooms/TeacherRooms'));
const TeacherRoomItems = React.lazy(() => import('./pages/TeacherRoomItems/TeacherRoomItems'));
const Invitegroup = React.lazy(() => import('./pages/Invitegroup/Invitegroup'));

const routers = [
  { path: '/admins', element: <Admins />, role: "admin" },
  { path: '/country', element: <Country />, role: "admin" },
  { path: '/language', element: <Language />, role: "admin" },
  { path: '/room', element: <Room />, role: "admin" },
  { path: '/plan', element: <Plan />, role: "admin" },
  { path: '/subject', element: <Subject />, role: "admin" },
  { path: '/badgets', element: <Badgets />, role: "admin" },
  { path: '/users', element: <Users />, role: "admin" },
  { path: '/question', element: <Question />, role: "admin" },
  { path: '/steps', element: <Steps />, role: "any" },
  { path: '/subjects/user', element: <SubjectsUser />, role: "any" },
  { path: '/subjects', element: <Subjects />, role: "any" },
  { path: '/subjects/:id', element: <SubjectsItem />, role: "any" },
  { path: '/questions/:id', element: <Questions />, role: "any" },
  { path: '/account', element: <Account />, role: "any" },
  { path: '/notification', element: <Notifications />, role: "any" },
  { path: '/password', element: <Password />, role: "any" },
  { path: '/personal-achievements', element: <PersonalAchievements />, role: "student" },
  { path: '/childrens', element: <Childrens />, role: "any" },
  { path: '/subscription', element: <Subscription />, role: "any" },
  { path: '/rooms', element: <TeacherRooms />, role: "any" },
  { path: '/rooms/:id', element: <TeacherRoomItems />, role: "any" },
  { path: '/invitegroup/:id', element: <Invitegroup />, role: "auth" },
];

function Routers() {
  const role = useSelector(state => state.auth.role)
  const isLogined = useSelector((state) => state.auth.isLogined);

  const getTokenApp = async () => {
    try {
      const messaging = getMessaging();
      if (messaging) {
        const token = await getToken(messaging, {
          vapidKey:
            'BP4HLmBsaJRHcf9Bfeou4mov0OZ_RHDLsVvUasfa82-w8pL8IXk09vSUBIEfFfOT-smkldVM82neYJnMryuMOKs',
        });
        sendWebToken({token})
      }
    } catch (error) {
      
    }
    
  };
  useEffect(() => {
    getTokenApp();
  }, [isLogined]);

  return (
    // <Router history={history}>
    <React.Suspense fallback={<SpinnerPage />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<RequireAuth />}>
            {routers.filter(_i => _i.role == 'any' || _i.role == role || _i.role == 'auth' && isLogined).map((_i) => (
              <Route key={_i.path} path={_i.path} element={_i.element} />
            ))}
            <Route path="*" to="/login"/>
          </Route>
          <Route path="/" element={<RequireNotAuth />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />}  />
            <Route path="register/:role" element={<RegisterRole />}  />
            <Route path="login/admin" element={<Login isAdmin />} />
            <Route path="forgot" element={<Forgot />} />
            <Route path="reset/:code" element={<Reset />} />
            <Route path="invitegroup/:id" element={<Invitegroup />} />
          </Route>
        </Route>
      </Routes>
    </React.Suspense>
    // </Router>
  );
}

function RequireAuth({ children }) {
  const location = useLocation();
  console.log("🚀 ~ file: routers.js ~ line 68 ~ RequireNotAuth ~ location", location)
  const profile = useSelector((state) => state.auth.profile);

  const isLogined = useSelector((state) => state.auth.isLogined);
  if (!isLogined) {
    return <Navigate to="/login" state={{ from: location }} />;
  } 
 
  if(location.pathname !== '/steps' && !profile.isEndRegister && profile.role != 'admin'){
    return <Navigate to="/steps" state={{ from: location }} />;
  }

  if(location.pathname == '/'){
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return <Outlet />;
}

function RequireNotAuth({ children }) {
  const location = useLocation();
  const isLogined = useSelector((state) => state.auth.isLogined);
  const role = useSelector((state) => state.auth.role);
  const profile = useSelector((state) => state.auth.profile);
  if (isLogined) {

    let to = '/subjects/user';
    if(role == 'admin'){
      to = '/admins'
    } else if(!profile.isEndRegister && role != 'admin'){
      to = '/steps'
    }

    return <Navigate to={to} state={{ from: location }} />;
  }

  return <Outlet />;
}

export default Routers;
