const defaultState = {
  isLoaded: false,
  country: null,
  language: null,
  room: null,
  subject: null,
  selected_theme: null,
  selected_sub_theme: null,
  list: [],
  themes: [],
  showModal: false,
  showQuestion: false,
  question: {
    // theme: '619f6a18e1095c7cd1ef985e',
    // question_type: 'type1',
  },
  theme: {},
};

const question = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return {
        ...state,
        list: action.data,
        isLoaded: true,
      };
    case 'SET_THEMES':
      return {
        ...state,
        themes: action.data,
        isLoaded: true,
      };
    case 'ADD_QUESTION':
      return {
        ...state,
        list: [...state.list, action.data],
        isLoaded: true,
      };
    case 'UPDATE_QUESTION':
      return {
        ...state,
        list: state.list.map((_i) =>
          _i._id == action.data._id ? { ..._i, ...action.data } : { ..._i }
        ),
        isLoaded: true,
      };
    case 'ADD_THEME':
      return {
        ...state,
        themes: [...state.themes, action.data],
        isLoaded: true,
      };
    case 'UPDATE_THEME':
      return {
        ...state,
        themes: state.themes.map((_i) =>
          _i._id == action.data._id ? { ..._i, ...action.data } : { ..._i }
        ),
        isLoaded: true,
      };
    case 'SET_KEY_QUESTION':
      return {
        ...state,
        [action.key]: action.data,
      };
    case 'DELETE_QUESTION':
      return {
        ...state,
        list: state.list.filter((_i) => _i._id != action.data),
        isLoaded: true,
      };
    case 'DELETE_THEME':
      return {
        ...state,
        themes: state.themes.filter((_i) => _i._id != action.data),
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default question;
