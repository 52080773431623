/* eslint-disable import/no-mutable-exports */
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';

export let messaging = null;
const initializedFirebaseApp = initializeApp({
  apiKey: 'AIzaSyAUu_vdN6j1FnQFfzyRF6Y8aj-I1X_fLbI',
  authDomain: 'istudy-96aad.firebaseapp.com',
  projectId: 'istudy-96aad',
  storageBucket: 'istudy-96aad.appspot.com',
  messagingSenderId: '103212962667',
  appId: '1:103212962667:web:435efe3ca0aa840dc0722e',
  measurementId: 'G-4DEC42ZHVQ',
});

if (isSupported()) {
  messaging = getMessaging();
}

export default initializedFirebaseApp;
