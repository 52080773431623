/* eslint-disable camelcase */
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { i18nReducer } from 'react-redux-i18n';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import country from './country';
import language from './language';
import room from './room';
import plan from './plan';
import subject from './subject';
import admin from './admin';
import user from './user';
import question from './question';
import badget from './badget';
import teacher_room from './teacher_room';

export default combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  i18n: i18nReducer,
  auth,
  country,
  language,
  room,
  plan,
  subject,
  admin,
  user,
  question,
  badget,
  teacher_room,
});
