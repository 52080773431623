const defaultState = {
  isLoaded: false,
  list: [],
  showModal: false,
  item: {},
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_USERS':
      return {
        ...state,
        list: action.data,
        isLoaded: true,
      };
    case 'SET_KEY_USER':
      return {
        ...state,
        [action.key]: action.data,
      };
    case 'DELETE_USER':
      return {
        ...state,
        list: state.list.filter((_i) => _i._id != action.data),
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default user;
