/* eslint-disable consistent-return */
import RestClient from './config/rest-client';

export function me() {
  return async (dispatch) => {
    try {
      dispatch({ type: 'IS_LOADED', data: false });
      const { profile, subscriptions } = await RestClient.get(
        'auth',
        'profile'
      );
      dispatch({ type: 'SET_PROFILE', data: profile, subscriptions });

      dispatch({ type: 'IS_LOADED', data: true });
    } catch (error) {
      setTimeout(() => {
        dispatch({ type: 'IS_LOADED', data: true });
      }, 500);
    }
  };
}

export function getCountNotification(data) {
  return async (dispatch) => {
    const data = await RestClient.get('notification', 'count');
    dispatch({
      type: 'SET_AUTH_KEY',
      key: 'notificationCount',
      data: data?.count || 0,
    });
  };
}

export async function sendWebToken(body) {
  try {
    const data = await RestClient.post('users', 'web_token', body);
    return data;
  } catch (error) {
    console.log("🚀 ~ file: actions.js ~ line 39 ~ sendWebToken ~ error", error)
  }
}

export function setUserGit(data) {
  return async (dispatch) => {
    localStorage.setItem('userGit', JSON.stringify(data));
    dispatch({
      type: 'SET_AUTH_KEY',
      key: 'userGit',
      data,
    });
  };
}

export function exit() {
  return async (dispatch) => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    RestClient.config({
      headers: {
        Accept: 'application/json',
        Authorization: undefined,
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: 'LOGOUT' });
    // history.push('/login');
  };
}
