/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
import RestClient from 'flex-rest-client';
import config from './index';

const rs = new RestClient();

function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  // let error = new Error(response ? (response.statusText || 'Error') : 'Error');
  // error.response = response;
  return Promise.resolve()
    .then(() => {
      return response.json();
    })
    .then((res) => {
      res.status = response.status;
      return Promise.reject(res);
    });
}

function makeRequest({ url, method, headers, bodyJSObject }) {
  const header_tmp = { ...this.headers, ...headers };

  let body = bodyJSObject ? JSON.stringify(bodyJSObject) : undefined;

  if (
    header_tmp.hasOwnProperty('Content-Type') &&
    header_tmp['Content-Type'] == 'multipart/form-data'
  ) {
    delete header_tmp['Content-Type'];
    body = bodyJSObject;
  }

  return fetch(url, {
    method: method || 'GET',
    headers: header_tmp,
    body,
  })
    .then(checkHttpStatus)
    .then((response) => {
      return response.json();
    });
}

const options = {
  baseUrl: config.apiUrl + '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  requestMethod: makeRequest,
};

if (localStorage.getItem('token')) {
  options.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}

if (sessionStorage.getItem('token')) {
  options.headers['Authorization'] =
    'Bearer ' + sessionStorage.getItem('token');
}

rs.config(options);

export default rs;
