const defaultState = {
  isLoaded: false,
  isLogined: false,
  role: '',
  profile: {},
  registerMessage: false,
  paymentModal: false,
  notificationCount: 0,
  subscriptions: [],
  userGit: {
    status: null,
    subject: false,
    theme: false,
    question: false,
  },
};

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.data,
        role: action.data.role,
        subscriptions: action.subscriptions || state.subscriptions,
        isLogined: true,
        isLoaded: true,
      };
    case 'IS_LOADED':
      return {
        ...state,
        isLoaded: action.data,
      };
    case 'SET_AUTH_KEY':
      return {
        ...state,
        [action.key]: action.data,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoaded: true,
        isLogined: false,
        role: 'user',
        profile: {},
      };
    default:
      return state;
  }
};

export default auth;
