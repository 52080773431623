import React from 'react';
import { Spinner } from 'reactstrap';

function SpinnerCustom() {
  return (
    <Spinner style={{ color: '#8F79EE', width: 50, height: 50 }} type="grow">
      Loading...
    </Spinner>
  );
}

export default SpinnerCustom;
