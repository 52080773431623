import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import rootReducer from './reducers/index';
import ru from './translations/ru.json';
import ua from './translations/ua.json';
import en from './translations/en.json';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const translationsObject = {
  ru,
  ua,
  en,
};

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

syncTranslationWithStore(store);

store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale(localStorage.getItem('languageSite') || 'ua'));

export default store;
