const defaultState = {
  isLoaded: false,
  country: null,
  list: [],
  showModal: false,
  item: {},
};

const language = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGES':
      return {
        ...state,
        list: action.data,
        isLoaded: true,
      };
    case 'ADD_LANGUAGE':
      return {
        ...state,
        list: [...state.list, action.data],
        isLoaded: true,
      };
    case 'UPDATE_LANGUAGE':
      return {
        ...state,
        list: state.list.map((_i) =>
          _i._id == action.data._id ? { ..._i, ...action.data } : { ..._i }
        ),
        isLoaded: true,
      };
    case 'DELETE_LANGUAGE':
      return {
        ...state,
        list: state.list.filter((_i) => _i._id != action.data),
        isLoaded: true,
      };
    case 'SET_KEY_LANGUAGE':
      return {
        ...state,
        [action.key]: action.data,
      };
    default:
      return state;
  }
};

export default language;
